import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import {
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  ButtonBase,
  Icon,
  Divider,
  Paper,
  Grid
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { isMobile } from "../isMobile"
import Page from '../components/page'
import { FAQHightlight } from '../components/faqhighlight'
import withRoot from '../withRoot'
import lock from '../static/faq/lock.png'
import permissionscheck from '../static/faq/permissions.png'
import settings from '../static/faq/settings.png'
import permissions from '../static/fitface-install/02.jpg'
import weatherios from '../static/faq/fitbit-settings-ios-v2.png'
import weatherandroid from '../static/faq/fitbit-settings-android.png'
import weatherupdate from '../static/faq/weatherupdate.png'
import profile1 from '../static/faq/profile1.png'
import profile2 from '../static/faq/profile2.png'
import profile3 from '../static/faq/profile3.png'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  faqContainer: {
    margin: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  img: {
    margin: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
    maxWidth: isMobile() ? '100%' : '60%',
  },
  section: {
    marginTop: theme.spacing(2),
  },
  highlight: {
    margin: 0,
  }
})

@withStyles(styles)
class ContactUs extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Fragment className={classes.faqContainer}>
        <Typography>You did not find your answer here.</Typography>
        <Typography>
          You can contact us for support or any question by email
          (click on the <Icon>help_outline</Icon> help button in the bottom of the page)<br />
          or join us on our <a className={classes.link} href="https://discord.gg/mxpD2Ea">Discord</a> chat.
        </Typography>
      </Fragment>
    )
  }
}

@withStyles(styles)
class Donation extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Fragment className={classes.faqContainer}>
        <Typography>You like FitFace and want to support us, make a donation.</Typography>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="CRVTW9WDFWWF2" />
          <ButtonBase type="submit">
            <svg width="183.516" height="40.703" viewBox="0 0 183.515625 40.703125" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="50%" y1="0%" x2="0%" y2="100%" id="a">
                  <stop stopColor="#ffba46" offset="0%" />
                  <stop stopColor="#ff8903" offset="100%" />
                </linearGradient>
                <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="b">
                  <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
                  <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <g fill="none" fillRule="evenodd">
                <rect stroke="#c55a00" fill="url(#a)" width="182.516" height="39.703" rx="3" />
                <path xmlns="http://www.w3.org/2000/svg" d="m 1517.2577,648.8839 q 18.0803,84.375 -4.0179,204.9107 -87.3884,445.9822 -567.52237,445.9822 H 901.521 q -25.11161,0 -44.19643,16.5737 -19.08482,16.5736 -24.10714,42.6897 l -4.01786,19.0848 -55.24553,347.5446 -2.00893,15.067 q -5.02232,26.1161 -24.60938,42.6897 Q 727.74868,1800 702.63707,1800 H 450.51658 q -21.0938,0 -33.1474,-15.067 -12.0535,-15.0669 -9.0401,-36.1607 9.0401,-56.25 26.6183,-168.75 17.5781,-112.5 26.6183,-168.75 9.0401,-56.25 27.1205,-168.2477 18.0804,-111.9978 27.1205,-168.2478 5.0224,-37.1652 43.192,-37.1652 H 690.5835 q 133.59375,2.0089 237.05357,-21.0938 175.78133,-39.1741 288.28133,-144.6428 102.4553,-95.4241 155.6919,-247.0982 24.1072,-70.3125 35.1563,-133.5938 1.0044,-6.0268 2.5111,-7.5335 1.5067,-1.5066 3.5157,-1.0044 2.0089,0.5022 6.0268,3.5156 79.3526,59.2634 98.4375,162.7232 z M 1344.4898,365.625 q 0,107.4777 -46.2054,237.0536 -80.3571,234.0401 -303.34822,316.4062 -113.50447,40.1786 -253.125,42.1875 0,1.0045 -90.40178,1.0045 l -90.40179,-1.0045 q -100.44643,0 -118.52683,96.4286 -2.0089,8.0357 -85.3794,532.3661 -1.0045,10.0446 -12.0536,10.0446 H 48.730785 q -22.0982,0 -36.6629,-16.5736 -14.5647001,-16.5737 -11.55130009,-38.6719 L 233.55228,67.2991 q 5.0223,-29.1295 27.6227,-48.2143 Q 283.77548,0 312.90498,0 h 600.66959 q 34.15179,0 97.93533,13.058 63.7835,13.0581 111.9978,32.1429 107.4776,41.183 164.2299,123.5491 56.7522,82.3661 56.7522,196.875 z" fill="#FFF" transform="matrix(.014 0 0 .014 10 8)" />
                <text fill="#fff" filter="url(#b)" fontFamily="Helvetica, arial, freesans, clean, sans-serif" fontSize="14" fontWeight="bold">
                  <tspan x="38" y="24" fill="#FFF">Support via PayPal</tspan>
                </text>
              </g>
            </svg>
          </ButtonBase>
        </form>
      </Fragment>
    )
  }
}

@withRoot
@withStyles(styles)
export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { section: -1 }
  }

  handleClick = (id) => {
    this.setState({ section: (this.state.section === id ? -1 : id) })
  }

  renderQuestion(question, idx) {
    const { classes } = this.props
    return (
      <Fragment key={idx}>
        <ListItem button onClick={() => this.handleClick(idx)}>
          <ListItemText>
            <Typography variant="h6">{question.title}</Typography>
          </ListItemText>
          {this.state.section === idx ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.section === idx} timeout="auto" unmountOnExit>
          <Paper className={classes.content}>
            {question.content}
          </Paper>
        </Collapse>
      </Fragment>
    )
  }

  renderSection(section, idx) {
    const { classes } = this.props
    return (
      <Fragment key={idx}>
        {section.title && <Typography id={section.id} color="primary" className={classes.faqContainer} variant="h4">{section.title}</Typography>}
        {section.q.map(function(question, i) {
          return this.renderQuestion(question, idx * 100 + i)
        }.bind(this))}
        <Divider className={classes.faqContainer} />
      </Fragment>
    )
  }

  render() {
    const { classes } = this.props
    const FAQ = [
      // main
      {
        title: 'General',
        q: [
          {
            title: 'What is FitFace?',
            content: (
              <Typography>
                FitFace is an awesome WYSIWYG online editor to create Fitbit clockfaces without writing one line of code.<br />
                <br />
                Designers can build their dream designs in a few minutes and share them in our gallery.<br />
                The FitFace gallery has 3000+ designs. There is certainly one that will suit you!<br />
                <br />
                FitFace also has a generic clockface in the Fitbit gallery from wich you can manage all the FitFace designs and switch between them with a simple touch.<br />
                No need to look for your previous designs in the Fitbit gallery anymore!<br />
                No need to unlock your paid clockfaces everytime :-)
              </Typography>
            )
          },
          {
            title: 'What are the supported Fitbit devices by FitFace?',
            content: (
              <Typography>
                All the Fitbit devices running Fitbit OS: Ionic, Versa, Versa Lite, Versa 2, Versa3, Sense
              </Typography>
            )
          },
          {
            title: 'What permissions are needed by the FitFace clockface?',
            content: (
              <Typography>
                Here are the needed permissions to have a fully functional app:<br />
                - Activity: read your activities for today and daily goals<br />
                - Heart Rate: read your heart-rate sensor in real-time<br />
                - User Profile: read your resting heart-rate only<br />
                - Internet: dowload designs and fetch the weather<br />
                - Location: read your GPS location for weather<br />
                - Run in background: fetch the weather periodically<br />
              </Typography>
            )
          },
          {
            title: 'How can I check authorized permissions for the FitFace clockface?',
            content: (
              <Typography>
                You can check that all permissions are enabled to have a fully functional app:<br />
                <img className={classes.img} src={permissionscheck} />
              </Typography>
            )
          },
          {
            title: "I don't know how to open the clokcface settings?",
            content: (
              <Typography>
                With FitFace, you need to authenticate before using a clockface. You can do that in the clockface settings. You can access it following the instruction below :<br />
                <img className={classes.img} src={settings} />
              </Typography>
            )
          },
        ]
      },
      // section unlock
      {
        title: 'Unlocking issue',
        q: [
          {
            title: 'I see a code on my watch, please help',
            content: (
              <Typography>
                <img className={classes.img} src={lock} />
                You see this message because you are using a paid design and the trial has expired.<br />
                If you want to continue, you&apos;ll have to buy it.<br />
                Or just install another clockface from FitFace or the Fitbit gallery.<br />
                More details <Link className={classes.link} to='/user/unlock'>here</Link>
              </Typography>
            )
          },
        ]
      },
      // section buying
      {
        title: 'Buying issue',
        q: [
          {
            title: 'How can I buy designs from the FitFace gallery?',
            content: (
              <Typography>
                You have found an awesome design in our gallery and you want to buy it.<br />
                No problem, everything is explained <Link className={classes.link} to='/user/buydesign'>here</Link>
              </Typography>
            )
          },
          {
            title: 'I bought a design in FitFace but I can\'t find it in the Fitbit gallery',
            content: (
              <Typography>
                A small part of the FitFace designs are published in the Fitbit gallery.<br />
                To install any FitFace design, you should use our FitFace generic clockface.<br />
                More details <Link className={classes.link} to='/user/install'>here</Link><br />
              </Typography>
            )
          },
          {
            title: 'I bought a clockface published in the Fitbit gallery, is it also available in the FitFace clockface?',
            content: (
              <Typography>
                Of course, if you bought a FitFace design published in the Fitbit gallery, you will see it in your purchases list in our generic FitFace clockface :-)<br />
                In the future, you should use this generic clockface, it is easier to manage your collection.<br />
                More details <Link className={classes.link} to='/user/install'>here</Link><br />
              </Typography>
            )
          },
          {
            title: 'Are my purchases lost if I uninstall the FitFace app?',
            content: (
              <Typography>
                No! Your purchases are stored in your account.<br />
                When you install FitFace again, just log in and you will find all your purchases again.
              </Typography>
            )
          },
          {
            title: 'I bought a design but I see the lock screen when I resinstall it from the Fitbit gallery',
            content: (
              <Typography>
                First you can&apos;t buy the same design twice. Our system will prevent it.<br />
                When you uninstall/resinstall a clockface, the Fitbit OS doesn&apos;t permit to store your previous payment status and we think you are a new user.<br />
                But you can unlock again your clockface easily. Just go the the clockface settings and enter your FitFace credentials.<br />
                More details <Link className={classes.link} to='/user/unlock'>here</Link>
              </Typography>
            )
          },
          {
            title: 'Can I have a refund if a design is not working as expected?',
            content: (
              <Fragment>

                <Typography>Yes, have a look at our <Link className={classes.link} to='/agreements/refund'>refund policy</Link>. Please contact our support if you want a refund.</Typography>
                <br />
                <Typography>
                  You have up to 15 days to ask for a refund after the purchase of the design.<br />
                  If you have any questions about our Refund Policy, send us an email.
                </Typography>
              </Fragment>
            )
          },
        ]
      },
      // section design issue
      {
        title: 'Design problems',
        q: [
          {
            title: 'I have a problem with a specific design, who can I contact?',
            content: (
              <Typography>
                You can contact the designer directly.<br />
                Go to our gallery, find the design and click on it.<br />
                There you will have a &apos;Contact Designer&apos; button
              </Typography>
            )
          },
          {
            title: 'I want to make my own design, how hard is it?',
            content: (
              <Typography>
                It&apos;s pretty easy. Like Paint!<br />
                More details <Link className={classes.link} to='/designer/editor'>here</Link>
              </Typography>
            )
          },
        ]
      },
      // weather
      {
        title: 'Weather',
        id: 'weather',
        q: [
          {
            title: 'How to enable weather updates correctly?',
            content: (
              <Typography>
                If the weather is not updating correctly, please make sure you gave all the needed permissions during the install fo the clockface.<br />
                <img className={classes.img} src={permissions} />
                Also make sure the Fitbit app has always access to your location, you will need to enable the following on your mobile phone.<br />
                <Typography className={classes.section}>On <b>iPhone</b>, open Settings app → scroll down to Fitbit</Typography>
                <img className={classes.img} src={weatherios} />
                <Typography className={classes.section}>On <b>Android</b>, open Settings → Apps & Notifications → Fitbit</Typography>
                <img className={classes.img} src={weatherandroid} />
                With these settings enable, go back to your Fitbit app and allow the phone and watch to sync and your weather updates should work thereafter.<br />
                <Typography className={classes.section}>
                  You could also check on the period when the phone last pushed the weather data to the watch.<br />
                  Go to your Fitbit app → Account → Clock Faces → Tap on watch → Settings → Enable the help tab<br />
                  From there you could see when the weather was last updated.
                </Typography>
                <img className={classes.img} src={weatherupdate} />
                Contact us if you still have issues.
              </Typography>
            )
          },
          {
            title: 'How often is the weather updated?',
            content: (
              <Typography>
                If you use a design with weather, it is updated at least once per hour.<br />
                You can check in the clockface settings page when was the last update (in the Help section).<br />
              </Typography>
            )
          },
          {
            title: 'The weather displayed on the clockface is different from the Fitbit weather app, why?',
            content: (
              <Typography>
                We are using <a className={classes.link} href="https://openweathermap.org/">Open Weather Map</a> as weather provider. The Fitbit one is <a className={classes.link} href="https://www.accuweather.org/">AccuWeather</a>.<br />
                You may see some minor differences between the 2 sources.
              </Typography>
            )
          },
        ]
      },
      // section account
      {
        title: 'Account issue',
        q: [
          {
            title: 'I forgot my FitFace password',
            content: (
              <Typography>
                Just go to the <a className={classes.link} href="https://fitface.xyz/auth/login">login page</a> and click on &apos;Forgot password ?&apos;<br />
                or follow this link <a className={classes.link} href="https://fitface.xyz/auth/reset-password">reset password</a>.
                You will receive an email with a link to reset your password.
              </Typography>
            )
          },
          {
            title: 'How do I change 12h/24h format?',
            content: (
              <Typography>
                You can change your preference in your fitbit <a className={classes.link} href="https://www.fitbit.com/settings/profile">profile</a>.
                You can also change displayed units (Mile/Km ...)
              </Typography>
            )
          },
          {
            title: 'How do I change displayed unit (Mile/Km ...) ?',
            content: (
              <Typography>
                You can change your preference in your fitbit <a className={classes.link} href="https://www.fitbit.com/settings/profile">profile</a>.
                You can also change displayed units directly in the Fitbit application :
                <Typography className={classes.section}>Account → App Settings → Units</Typography>
                <img className={classes.img} src={profile1} />
                <img className={classes.img} src={profile2} />
                <img className={classes.img} src={profile3} />
              </Typography>
            )
          },
        ]
      },
      // section contact
      {
        title: 'Contact',
        q: [
          {
            title: 'Need more? Contact Us',
            content: (<ContactUs />)
          },
          {
            title: 'FitFace is awesome. I want to support you',
            content: (<Donation />)
          }
        ]
      }
    ]

    return (
      <Page toggleTheme={this.props.toggleTheme}>
        <Typography color="primary" align="center" variant="h4">Welcome to FitFace Documentation</Typography>
        <br />
        <Grid container direction="row" justify="center" align="center">
          <FAQHightlight className={classes.highlight} title="Not Unlocked?" icon="lock" url='/faq/notunlocked'>
            Your fitbit is still showing a code when you already paid?
          </FAQHightlight>
          <FAQHightlight className={classes.highlight} title="Already Paid?" icon="attach_money" url='/faq/alreadypaid'>
            Is it asking you to pay when you already did?
          </FAQHightlight>
          <FAQHightlight className={classes.highlight} title="Can't find it?" icon="search" url='/faq/cantfind'>
            You did not find your clockface on Fitbit Gallery
          </FAQHightlight>
          <FAQHightlight className={classes.highlight} title="Weather update?" icon="cloud_queue" url='/faq/weather'>
            Weather is not updating on your watch
          </FAQHightlight>
        </Grid>
        <br />
        <Typography>
          Already a FitFace user and want a quick answer, here is our FAQ.<br />
          Otherwise, you should have a look to the menu, and browse any chapter you are interested in.
        </Typography>
        <br />
        {
          FAQ.map(function(section, idx) {
            return this.renderSection(section, idx)
          }.bind(this))
        }
      </Page>
    )
  }
}
