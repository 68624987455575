import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { navigate } from 'gatsby'

import {
  Grid,
  Button,
  Typography,
  Icon,
} from '@material-ui/core'

const styles = theme => ({
  note: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: '225px',
    maxWidth: '225px',
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "5px",
    borderColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "5px",
      borderColor: theme.palette.secondary.main,
    },
  },
  infoIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  subtitle: {
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
})

@withStyles(styles)
export class FAQHightlight extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Button className={classes.note} onClick={() => navigate(this.props.url)}>
        <Grid container direction="column" justify="center" align="center">
          <Icon className={classes.infoIcon}>{this.props.icon}</Icon>
          <Typography align="center" variant="h6">{this.props.title}</Typography>
          <Typography align="center" variant="caption" className={classes.subtitle}>{this.props.children}</Typography>
        </Grid>
      </Button>
    )
  }
}

FAQHightlight.propTypes = {
  children: PropTypes.node.isRequired,
}
